<template>
  <div class="imgRestore">
    <div class="modelBox" v-if="windowWidth">
      <span v-show="language_type" class="modelTitle">模型选择：</span>
      <span v-show="!language_type" class="modelTitle">Model selection：</span>
      <div
        :class="PitchOn_first ? 'PitchOn_first' : 'modelSelect'"
        @click="modelFirst"
      >
        YOLO-World-X-1280
      </div>
      <!-- <div
        :class="PitchOn_last ? 'PitchOn_last' : 'modelSelect'"
        @click="modelLast"
      >
        新模型
      </div> -->
    </div>
    <div class="functionBox" v-if="windowWidth">
      <div
        class="functionLeft"
        v-loading="loading"
        element-loading-background="#e2e8ef"
        v-if="PitchOn_first"
      >
        <div class="newimgUrl" @click="getSrcS()">
          <el-image
            id="canvasImg"
            class="imgUrl"
            ref="cfImg"
            :src="imgUrl"
            :preview-src-list="srcList"
          >
          </el-image>
          <div
            v-for="(item, index) in list"
            v-show="eyeOpen"
            :key="index"
            class="canvasDiv"
            :class="nowIndex == index ? 'activeCanvas' : ''"
            :style="
              nowIndex == index
                ? { background: rgba }
                : { background: 'transparent' }
            "
          >
            <span class="canvasText">{{ item.label }}</span>
          </div>
          <div
            class="move"
            @mousemove="handleMouseMove"
            @mouseleave="handleMouseLeave"
          ></div>
        </div>
      </div>
      <div class="functionRight" v-if="PitchOn_first">
        <img
          @click="changImg(item, index)"
          class="imgList"
          v-for="(item, index) in imgList"
          :key="index"
          :src="item.url"
          alt=""
          :class="{ active: index === isActice }"
        />
      </div>
      <div class="t-contentBox" v-show="PitchOn_last">
        <div class="t-Box">
          <div class="title_box">
            <p class="title">视觉提示图像</p>
            <el-upload
              class="upload-demo"
              action=""
              :limit="9"
              :on-change="handleChange"
              :file-list="fileList"
              :auto-upload="false"
              :show-file-list="false"
            >
              <el-button class="upload" icon="el-icon-upload"
                >上传图像</el-button
              >
            </el-upload>
          </div>
          <div class="img_box toop_box">
            <img :src="toopImg" alt="" />
            <canvas
              class="markCanvas"
              width="450"
              height="450"
              tabindex="0"
            ></canvas>
          </div>
        </div>
        <div class="t-center">
          <div class="btn" @click="tsubmit">
            <i class="el-icon-right"></i>
            <p>开始检测</p>
          </div>
          <div class="btn open" @click="refresh">
            <i class="el-icon-refresh"></i>
            <p>切换样例</p>
          </div>
        </div>
        <div class="t-Box">
          <div class="title_box">
            <p class="title">待检图像</p>
            <el-upload
              class="upload-demo"
              action=""
              :limit="9"
              :on-change="handleChange2"
              :file-list="fileList2"
              :auto-upload="false"
              :show-file-list="false"
            >
              <el-button class="upload" icon="el-icon-upload"
                >上传图像</el-button
              >
            </el-upload>
          </div>
          <div class="img_box">
            <img :src="testImg" alt="" />
          </div>
        </div>
      </div>
      <el-drawer
        direction="ltr"
        :before-close="drawerhandleClose"
        :visible.sync="innerDrawer"
        :with-header="false"
        size="4rem"
      >
        <div class="drawerContentBox">
          <p>图片</p>
          <el-radio-group v-model="radio1" @input="chooseRadio">
            <el-radio-button label="first">选择样例图像</el-radio-button>
            <el-radio-button label="second">上传图像</el-radio-button>
          </el-radio-group>
          <ul class="drawerImgBox" v-show="radio1 == 'first'">
            <li
              v-for="(item, index) in drawerImgList"
              :key="index"
              @click="chooseImg(index)"
              :class="drawerImgIndex == index ? 'active' : ''"
            >
              <img :src="item.imgl" alt="" />
              <img :src="item.imgr" alt="" />
              <i v-if="drawerImgIndex == index" class="el-icon-success"></i>
            </li>
          </ul>
          <div class="drawerUploadBox" v-show="radio1 == 'second'">
            <p>视觉提示图像</p>
            <el-upload
              class="upload-demo"
              drag
              action=""
              :on-change="uploadhandleChange"
              :file-list="uploadfileList"
              :auto-upload="false"
              :show-file-list="false"
            >
              <i v-show="uploadtoopImg == ''" class="el-icon-upload"></i>
              <div v-show="uploadtoopImg == ''" class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <img
                v-show="uploadtoopImg != ''"
                class="upload_img"
                :src="uploadtoopImg"
                alt=""
              />
            </el-upload>
            <p>待检图像</p>
            <el-upload
              class="upload-demo"
              drag
              action=""
              :on-change="uploadhandleChange2"
              :file-list="uploadfileList2"
              :auto-upload="false"
              :show-file-list="false"
            >
              <i v-show="uploadtestImg == ''" class="el-icon-upload"></i>
              <div v-show="uploadtestImg == ''" class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <img
                v-show="uploadtestImg != ''"
                class="upload_img"
                :src="uploadtestImg"
                alt=""
              />
            </el-upload>
          </div>
        </div>
        <div class="drawerBtnBox">
          <p class="redToopis">{{ redToopis }}</p>
          <el-button type="primary" round @click="drawerSure">确 认</el-button>
        </div>
      </el-drawer>
      <el-dialog
        :title="language_type ? '参数设置' : 'Parameter settings'"
        :visible.sync="dialogVisible"
        width="10rem"
        @closed="closedDialog"
      >
        <li>
          <p class="label" v-show="language_type">
            最大检测数量：<span class="num">{{ boxNum }}</span>
          </p>
          <p class="label_en" v-show="!language_type">
            Maximum detection quantity：<span class="num">{{ boxNum }}</span>
          </p>
          <el-slider v-model="boxNum" :max="300"></el-slider>
        </li>
        <li>
          <p class="label" v-show="language_type">
            NMS阈值：<span class="num">{{ nmsNum }}</span>
          </p>
          <p class="label_en" v-show="!language_type">
            NMS threshold：<span class="num">{{ nmsNum }}</span>
          </p>
          <el-slider v-model="nmsNum" :max="1" :step="0.01"></el-slider>
        </li>
        <li>
          <p class="label" v-show="language_type">
            置信度阈值：<span class="num">{{ scoreNum }}</span>
          </p>
          <p class="label_en" v-show="!language_type">
            Confidence threshold：<span class="num">{{ scoreNum }}</span>
          </p>
          <el-slider v-model="scoreNum" :max="1" :step="0.01"></el-slider>
        </li>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">{{
            language_type ? "取 消" : "cancel"
          }}</el-button>
          <el-button type="primary" @click="sureData">{{
            language_type ? "确 定" : "confirm"
          }}</el-button>
        </span>
      </el-dialog>
      <el-dialog
        class="tagdia"
        title=""
        :visible.sync="dialogVisibleTag"
        width="10rem"
      >
        <el-tag
          v-for="(item, index) in tagListdig"
          :key="index"
          effect="dark"
          @click="watchTags(item)"
          >{{ item }}</el-tag
        >
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisibleTag = false">{{
            language_type ? "确 定" : "confirm"
          }}</el-button>
        </span>
      </el-dialog>
      <div class="btnBox">
        <div class="tagBox" v-if="PitchOn_first">
          <span class="label" v-show="language_type"
            >标签（当前版本仅支持英文）：</span
          >
          <span class="label_en" v-show="!language_type"
            >Tags (English-only)：</span
          >
          <div class="tag_con">
            <el-tooltip
              :content="
                language_type
                  ? '点击查看具体标签'
                  : 'Click to view specific tags'
              "
              placement="top"
              v-for="(item, index) in tagList"
              :key="index"
              :disabled="item != 'MS-COCO' ? true : false"
            >
              <el-tag
                effect="dark"
                closable
                @click="watchTags(item)"
                @close="handleClose(index)"
                >{{ item }}</el-tag
              >
            </el-tooltip>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput"
              >+ New Tag</el-button
            >
            <el-tooltip
              :content="language_type ? '关闭检测框' : 'Close detection box'"
              placement="top"
            >
              <span
                class="iconfont icon-yanjingkaiyan"
                v-show="eyeOpen && eyeBtn"
                @click="showstoke(eyeOpen)"
              ></span>
            </el-tooltip>
            <el-tooltip
              :content="language_type ? '查看检测框' : 'View detection box'"
              placement="top"
            >
              <span
                class="iconfont icon-yanjingguan1"
                v-show="!eyeOpen && eyeBtn"
                @click="showstoke(eyeOpen)"
              ></span>
            </el-tooltip>
          </div>
        </div>
        <p class="prompt" v-show="language_type && PitchOn_first">
          样例图片来源于<a href="https://cocodataset.org/" target="_blank"
            >[MS-COCO数据集](https://cocodataset.org/)</a
          >
        </p>
        <p class="prompt" v-show="!language_type && PitchOn_first">
          Sample images from
          <a href="https://cocodataset.org/" target="_blank"
            >MS-COCO (https://cocodataset.org/)</a
          >
        </p>
        <p class="cooperation" v-show="language_type">
          商务合作、批量化调用等请联系 yixiaoge@tencent.com
        </p>
        <p class="cooperation" v-show="!language_type">
          Please contact yixiaoge@tencent.com for business collaborations, bulk
          invocation, and other related inquiries.
        </p>
        <div class="options" @click="dialogVisible = true" v-if="PitchOn_first">
          <span class="iconfont icon-xiaoshou"></span>
          <span v-show="language_type">更改参数设置</span>
          <span v-show="!language_type">Change parameter settings</span>
        </div>
        <div class="btnLeft" v-show="PitchOn_first"></div>
        <div class="btnRight">
          <span class="shareTo" v-show="language_type">分享至：</span>
          <span class="shareTo" v-show="!language_type">Share To :</span>
          <div class="shareIcon" @click="wxShareAppMessage">
            <img
              class="normal_img"
              src="../../../assets/img/icon/wx1.png"
              alt=""
            />
            <img
              class="hover_img"
              src="../../../assets/img/icon/wx.png"
              alt=""
            />
            <div class="wx_code">
              <div class="code" v-show="language_type" ref="qrCodeUrl"></div>
              <div class="code" v-show="!language_type" ref="qrCodeUrl2"></div>
              <p v-show="language_type">分享到微信朋友圈</p>
              <p v-show="!language_type">Share with wechat</p>
            </div>
          </div>
          <div class="shareIcon" @click="share_weibo">
            <img
              class="normal_img"
              src="../../../assets/img/icon/weibo1.png"
              alt=""
            />
            <img
              class="hover_img"
              src="../../../assets/img/icon/weibo.png"
              alt=""
            />
          </div>
          <div class="shareIcon" @click="shareToQQ">
            <img
              class="normal_img"
              src="../../../assets/img/icon/qq1.png"
              alt=""
            />
            <img
              class="hover_img"
              src="../../../assets/img/icon/qq.png"
              alt=""
            />
          </div>
          <div class="shareIcon" @click="shareToDouban">
            <img
              class="normal_img"
              src="../../../assets/img/icon/dou1.png"
              alt=""
            />
            <img
              class="hover_img"
              src="../../../assets/img/icon/dou.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="functionBox_mini" v-if="windowWidth == false">
      <p class="message" v-if="language_type">
        建议在PC端打开链接或扫描二维码体验Demo
      </p>
      <p class="message" v-if="!language_type">
        It is recommended to open the link or scan the QR code on the PC side to
        experience the Demo
      </p>
      <div class="wxapp_box">
        <img src="../../../assets/img/wxapp.jpg" alt="" />
      </div>
    </div>
  </div>
</template>
  <script>
import QRCode from "qrcodejs2";
import wx from "weixin-js-sdk";

import { draw } from "../../../assets/js/draw"; // 矩形绘制方法

export default {
  // 监听页面宽度
  created() {
    window.addEventListener("resize", this.getWidth);
    this.getWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.getWidth);
  },
  data() {
    return {
      windowWidth: true,
      imgList: [
        {
          index: 2,
          url: "https://50058.gzc.svp.tencent-cloud.com/0b53vmaekaaaayan7ydkcntivk6diwvqaria.f0.jpg?dis_k=de6409d40c44dbebcec6c2e341f2d28c&dis_t=1718262446",
          url2: "https://50058.gzc.svp.tencent-cloud.com/0b53deapyaaa6iagoylkbjtiugod7qmqb7aa.f0.jpg?dis_k=9bf337b4f153af17bce22e6f528e2823&dis_t=1718262763",
          list: [
            { label: "starbucks 0.73", bbox: [165, 639, 425, 1002] },
            { label: "smiling dog 0.73", bbox: [269, 83, 925, 937] },
            { label: "laptop 0.70", bbox: [392, 411, 1024, 1024] },
          ],
          dialogData: {
            boxNum: 100,
            nmsNum: 0.5,
            scoreNum: 0.3,
          },
        },
        {
          index: 1,
          url: "https://50058.gzc.svp.tencent-cloud.com/0b53dqanuaaacyal7ntj3vtiuhgd3ioabwqa.f0.png?dis_k=e57c79f091c74bacda9c5d3a4bb376a2&dis_t=1718183178",
          url2: "https://50058.gzc.svp.tencent-cloud.com/0b53kuaf4aaalqadhutjdjtiuvodlzkqaxqa.f0.jpg?dis_k=b61c0502cfb47aeb05a0e7baf9522c8d&dis_t=1718185225",
          list: [
            { label: "big cat 0.77", bbox: [276, 10, 1113, 615] },
            { label: "small cat 0.38", bbox: [642, 21, 860, 331] },
          ],
          dialogData: {
            boxNum: 100,
            nmsNum: 0.5,
            scoreNum: 0.1,
          },
        },
        {
          index: 3,
          url: "https://50058.gzc.svp.tencent-cloud.com/0b53qqadcaaazaafiudjnftivbgdggcaamia.f0.png?dis_k=4f2546cbc81a1ae20363a2c713b1d091&dis_t=1718183245",
          url2: "https://50058.gzc.svp.tencent-cloud.com/0b53wqae2aaa4uacbtdjaftivngdjw2aatia.f0.jpg?dis_k=f913a015378ea128a8a5395305396479&dis_t=1718185297",
          list: [
            { label: "banana 0.78", bbox: [133, 247, 432, 685] },
            { label: "red apple 0.74", bbox: [377, 99, 601, 312] },
            { label: "green apple 0.73", bbox: [370, 294, 637, 564] },
            { label: "green apple 0.67", bbox: [240, 507, 480, 717] },
            { label: "red apple 0.60", bbox: [662, 421, 890, 637] },
            { label: "red apple 0.50", bbox: [619, 130, 839, 350] },
            { label: "green apple 0.41", bbox: [740, 285, 962, 501] },
            { label: "red apple 0.30", bbox: [481, 526, 727, 717] },
            { label: "green apple 0.28", bbox: [557, 256, 743, 480] },
            { label: "green apple 0.22", bbox: [114, 231, 306, 446] },
            { label: "green apple 0.21", bbox: [107, 93, 961, 722] },
          ],
          dialogData: {
            boxNum: 100,
            nmsNum: 0.7,
            scoreNum: 0.1,
          },
        },
      ],
      //旧照片
      imgUrl:
        "https://50058.gzc.svp.tencent-cloud.com/0b53vmaekaaaayan7ydkcntivk6diwvqaria.f0.jpg?dis_k=de6409d40c44dbebcec6c2e341f2d28c&dis_t=1718262446",
      //新照片
      NewimgUrl: "",
      isActice: 0,
      value: 50,
      suffix: "", //文件拓展名
      ImgName: "", //文件名
      strImgName: "",
      downImg: "",
      uploadtake: false,
      dowmtake: false,
      mouse_disable: false,
      first: 1,
      loading: false,
      language_type: true,
      canvasWidth: 0,
      canvasHeight: 0,
      downImgType: "",
      srcList: [],
      //模型选择
      PitchOn_first: true,
      PitchOn_last: false,
      PitchOn: false,
      parameter: "",
      file: "",
      file2: "",
      dialogVisible: false,
      boxNum: 100,
      nmsNum: 0.5,
      scoreNum: 0.3,
      dialogData: {
        boxNum: 100,
        nmsNum: 0.5,
        scoreNum: 0.3,
      },
      tagList: ["smiling dog", "starbucks", "laptop"],
      tagListdig: [],
      inputVisible: false,
      inputValue: "",
      dialogVisibleTag: false,
      list: [
        { label: "starbucks 0.73", bbox: [165, 639, 425, 1002] },
        { label: "smiling dog 0.73", bbox: [269, 83, 925, 937] },
        { label: "laptop 0.70", bbox: [392, 411, 1024, 1024] },
      ],
      multiple: {
        pointMultiple: 0,
        Imgx: 0,
        Imgy: 0,
      },
      indexArr: [],
      nowIndex: 9999,
      colorTotal: [
        "#c71585",
        "#ee82ee",
        "#9932cc",
        "#8a2be2",
        "#9370db",
        "#8b1a1a",
        "#ee3b3b",
        "#ff8c69",
        "#cd7054",
        "#000000",
        "#ffa500",
        "#cd8500",
        "#5f9ea0",
        "#66cdaa",
        "#006400",
        "#556b2f",
        "#8b864e",
        "#8b8b7a",
        "#8b8b00",
        "#90ee00",
        "#8b008b",
        "#008b8b",
      ],
      rgba: "",
      eyeOpen: true,
      eyeBtn: true,
      innerDrawer: false,
      markList: [], // 标记内容数组
      fileList: [],
      fileList2: [],
      uploadfileList: [],
      uploadfileList2: [],
      toopImg:
        "https://50058.gzc.svp.tencent-cloud.com/0b53vmaekaaaayan7ydkcntivk6diwvqaria.f0.jpg?dis_k=de6409d40c44dbebcec6c2e341f2d28c&dis_t=1718262446",
      testImg:
        "https://50058.gzc.svp.tencent-cloud.com/0b53vmaekaaaayan7ydkcntivk6diwvqaria.f0.jpg?dis_k=de6409d40c44dbebcec6c2e341f2d28c&dis_t=1718262446",
      uploadtoopImg: "",
      uploadtestImg: "",
      toopMultiple: {
        pointMultiple: 0,
        Imgx: 0,
        Imgy: 0,
      },
      testMultiple: {
        pointMultiple: 0,
        Imgx: 0,
        Imgy: 0,
      },
      radio1: "first",
      drawerImgList: [
        {
          imgl: "https://50058.gzc.svp.tencent-cloud.com/0b53vmaekaaaayan7ydkcntivk6diwvqaria.f0.jpg?dis_k=de6409d40c44dbebcec6c2e341f2d28c&dis_t=1718262446",
          imgr: "https://50058.gzc.svp.tencent-cloud.com/0b53vmaekaaaayan7ydkcntivk6diwvqaria.f0.jpg?dis_k=de6409d40c44dbebcec6c2e341f2d28c&dis_t=1718262446",
        },
        {
          imgl: "https://50058.gzc.svp.tencent-cloud.com/0b53vmaekaaaayan7ydkcntivk6diwvqaria.f0.jpg?dis_k=de6409d40c44dbebcec6c2e341f2d28c&dis_t=1718262446",
          imgr: "https://50058.gzc.svp.tencent-cloud.com/0b53vmaekaaaayan7ydkcntivk6diwvqaria.f0.jpg?dis_k=de6409d40c44dbebcec6c2e341f2d28c&dis_t=1718262446",
        },
      ],
      drawerImgIndex: 0,
      redToopis: "",
    };
  },
  mounted() {
    this.drawPoint(this.list);
    this.imgToFile(this.imgUrl);
    if (window.innerWidth > 1032) {
      this.creatQrCode();
    }
    if (this.$route.path.slice(1, 3) == "zh") {
      this.language_type = true;
    } else {
      this.language_type = false;
    }
    this.createBtn();
    let str =
      "person, bicycle, car, motorcycle, airplane, bus, train, truck, boat, traffic light, fire hydrant, stop sign, parking meter, bench, bird, cat, dog, horse, sheep, cow, elephant, bear, zebra, giraffe, backpack, umbrella, handbag, tie, suitcase, frisbee, skis, snowboard, sports ball, kite, baseball bat, baseball glove, skateboard, surfboard, tennis racket, bottle, wine glass, cup, fork, knife, spoon, bowl, banana, apple, sandwich, orange, broccoli, carrot, hot dog, pizza, donut, cake, chair, couch, potted plant, bed, dining table, toilet, tv, laptop, mouse, remote, keyboard, cell phone, microwave, oven, toaster, sink, refrigerator, book, clock, vase, scissors, teddy bear, hair drier, toothbrush";
    this.tagListdig = str.split(",");
  },
  watch: {
    $route(to) {
      if (to.path.slice(1, 3) == "zh") {
        this.language_type = true;
      } else {
        this.language_type = false;
      }
    },
  },
  methods: {
    createBtn() {
      let btnHtml = `<div class="uploadBtn">
      ${this.language_type ? "本地上传" : "Upload"}
      <input
        type="file"
        accept="image/*"
        ref="avatarInput"
        multiple
        class="uploadInput"
      />
      </div>
      <div class="uploadBtn">
        ${this.language_type ? "确认识别" : "Submit"}
      </div>
      <div
        class="${this.mouse_disable ? "mouse_disable" : "dowmBtn"} downBtn">
        ${this.language_type ? "下载图片" : "Download"}
      </div>`;
      let btnLeft = document.getElementsByClassName("btnLeft")[0];
      btnLeft.innerHTML = btnHtml;
      let input = document.getElementsByClassName("uploadInput");
      input[0].addEventListener("change", this.uploadImage);
      let btn = document.getElementsByClassName("uploadBtn");
      btn[1].addEventListener("click", this.submit);
      let down = document.getElementsByClassName("downBtn");
      down[0].addEventListener("click", this.downloadIamge);
    },
    modelFirst() {
      this.PitchOn_first = true;
      this.PitchOn_last = false;
      this.drawPoint(this.list);
    },
    modelLast() {
      this.PitchOn_last = true;
      this.PitchOn_first = false;
      this.sureMultiple(this.toopImg, "toop");
      this.sureMultiple(this.testImg, "test");
    },
    choores() {
      this.PitchOn_last = false;
      this.PitchOn_first = false;
      this.parameter = "defs";
      this.PitchOn = true;
      this.sendFn();
      if (this.parameter == "defs") {
        this.NewimgUrl = this.imgList[this.isActice].url4;
      }
    },
    chooseImg(i) {
      this.drawerImgIndex = i;
    },
    sendFn() {
      if (this.file !== "") {
        this.uploadImage(this.file);
      }
    },
    getSrcS() {
      this.srcList = [];
      if (this.eyeOpen) {
        this.srcList.push(this.NewimgUrl);
      } else {
        this.srcList.push(this.imgUrl);
      }
      this.$refs.cfImg.clickHandler();
    },
    // 获取页面宽度
    getWidth() {
      if (window.innerWidth > 1032) {
        this.windowWidth = true;
      } else {
        this.windowWidth = false;
      }
    },
    changImg(item, index) {
      this.imgToFile(item.url);
      this.imgUrl = item.url;
      this.list = item.list;
      this.dialogData = item.dialogData;
      this.closedDialog();
      this.eyeOpen = true;
      this.eyeBtn = true;
      this.nowIndex = 9999;
      this.NewimgUrl = item.url2;
      this.drawPoint(item.list);
      this.isActice = index;
      this.file = "";
      if (index == 0) {
        this.tagList = ["smiling dog", "starbucks", "laptop"];
      } else if (index == 1) {
        this.tagList = ["small cat", "big cat"];
      } else if (index == 2) {
        this.tagList = ["green apple", "red apple", "banana"];
      }
    },
    showstoke(eye) {
      this.eyeOpen = !eye;
    },
    watchTags(i) {
      if (i != "MS-COCO") {
        return;
      } else {
        this.dialogVisibleTag = true;
      }
    },
    handleClose(index) {
      this.tagList.splice(index, 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    drawerhandleClose() {
      this.innerDrawer = false;
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.tagList.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    sureData() {
      this.dialogVisible = false;
      this.dialogData.boxNum = this.boxNum;
      this.dialogData.nmsNum = this.nmsNum;
      this.dialogData.scoreNum = this.scoreNum;
    },
    closedDialog() {
      this.boxNum = this.dialogData.boxNum;
      this.nmsNum = this.dialogData.nmsNum;
      this.scoreNum = this.dialogData.scoreNum;
    },
    imgToFile(url) {
      let image = new Image();
      image.src = url;
      image.setAttribute("crossOrigin", "Anonymous");
      image.onload = () => {
        let base64 = this.getBase64Image(image);
        this.file = this.btof(base64, url.slice(90));
        this.ImgName = url.slice(90);
      };
    },
    chooseRadio() {
      this.redToopis = "";
    },
    handleMouseMove(e) {
      let x = e.offsetX - this.multiple.Imgx;
      let y = e.offsetY - this.multiple.Imgy;
      let brr = [];
      let indexArr = [];
      for (let index = 0; index < this.list.length; index++) {
        let arr = this.list[index].bbox;
        if (
          x >= arr[0] * this.multiple.pointMultiple &&
          x <= arr[2] * this.multiple.pointMultiple &&
          y >= arr[1] * this.multiple.pointMultiple &&
          y <= arr[3] * this.multiple.pointMultiple
        ) {
          brr.push(index);
        }
      }
      let step = 1;
      for (let i = 0; i < brr.length; i++) {
        if (brr.length == 1) {
          indexArr.push(brr[i]);
        } else if (i + step < brr.length) {
          let num = 0;
          if (this.list[brr[i]].bbox[0] <= this.list[brr[i + step]].bbox[0]) {
            num++;
          } else {
            num--;
          }
          if (this.list[brr[i]].bbox[1] <= this.list[brr[i + step]].bbox[1]) {
            num++;
          } else {
            num--;
          }
          if (this.list[brr[i]].bbox[2] >= this.list[brr[i + step]].bbox[2]) {
            num++;
          } else {
            num--;
          }
          if (this.list[brr[i]].bbox[3] >= this.list[brr[i + step]].bbox[3]) {
            num++;
          } else {
            num--;
          }
          if (num == 4) {
            // i 包含 i+step
            if (i + step == brr.length - 1) {
              indexArr.push(brr[i + step]);
            } else {
              i = i + step - 1;
              step = 1;
            }
          } else if (num == -4) {
            // i+step 包含 i
            if (i + step == brr.length - 1) {
              indexArr.push(brr[i]);
            } else {
              i--;
              step++;
            }
          } else {
            // i 与 i+step 交叉
            let offset1 =
              this.list[brr[i]].bbox[2] +
              this.list[brr[i]].bbox[3] -
              this.list[brr[i]].bbox[0] -
              this.list[brr[i]].bbox[1];
            let offset2 =
              this.list[brr[i + step]].bbox[2] +
              this.list[brr[i + step]].bbox[3] -
              this.list[brr[i + step]].bbox[0] -
              this.list[brr[i + step]].bbox[1];
            if (i + step == brr.length - 1) {
              if (offset1 <= offset2) {
                indexArr.push(brr[i]);
              } else {
                indexArr.push(brr[i + step]);
              }
            } else {
              if (offset1 <= offset2) {
                i--;
                step++;
              } else {
                i = i + step - 1;
                step = 1;
              }
            }
          }
        }
      }
      if (indexArr.length > 0) {
        if (indexArr[0] != this.nowIndex) {
          let hex = this.colorTotal[indexArr[0]];
          let opacity = "0.5";
          let red = parseInt(hex.substring(1, 3), 16);
          let green = parseInt(hex.substring(3, 5), 16);
          let blue = parseInt(hex.substring(5, 7), 16);
          this.rgba = `rgba(${red},${green}, ${blue},${opacity})`;
          this.nowIndex = indexArr[0];
        }
      } else {
        this.nowIndex = 9999;
      }
    },
    handleMouseLeave() {
      this.nowIndex = 9999;
    },
    handleChange(file, fileList) {
      var that = this;
      let reader = new FileReader();
      reader.onloadend = (f) => {
        var objSrc = f.target.result;
        var imageObj = new Image();
        imageObj.src = objSrc;
        imageObj.onload = () => {
          if (imageObj.width > 4000 || imageObj.height > 4000) {
            that.$message({
              type: "warning",
              message: that.language_type
                ? "图片尺寸过大，请重新上传"
                : "The picture size is too large, please upload it again",
            });
            if (fileList.length > 1) {
              fileList.pop();
            }
            that.fileList = fileList;
          } else if (imageObj.width < 400 || imageObj.height < 400) {
            that.$message({
              type: "warning",
              message: that.language_type
                ? "图像分辨率太小，仅限分辨率大于400*400的图像"
                : "Image resolution is too small, only images with a resolution greater than 400*400",
            });
            if (fileList.length > 1) {
              fileList.pop();
            }
            that.fileList = fileList;
          } else {
            that.$message({
              type: "success",
              message: that.language_type
                ? "图片上传成功"
                : "Image uploaded successfully",
            });
            if (fileList.length > 1) {
              fileList.shift();
            }
            that.fileList = fileList;
            that.toopImg = f.target.result;
            that.sureMultiple(f.target.result, "toop");
          }
        };
      };
      reader.readAsDataURL(file.raw);
    },
    handleChange2(file, fileList) {
      var that = this;
      let reader = new FileReader();
      reader.onloadend = (f) => {
        var objSrc = f.target.result;
        var imageObj = new Image();
        imageObj.src = objSrc;
        imageObj.onload = () => {
          if (imageObj.width > 4000 || imageObj.height > 4000) {
            that.$message({
              type: "warning",
              message: that.language_type
                ? "图片尺寸过大，请重新上传"
                : "The picture size is too large, please upload it again",
            });
            if (fileList.length > 1) {
              fileList.pop();
            }
            that.fileList2 = fileList;
          } else if (imageObj.width < 400 || imageObj.height < 400) {
            that.$message({
              type: "warning",
              message: that.language_type
                ? "图像分辨率太小，仅限分辨率大于400*400的图像"
                : "Image resolution is too small, only images with a resolution greater than 400*400",
            });
            if (fileList.length > 1) {
              fileList.pop();
            }
            that.fileList2 = fileList;
          } else {
            that.$message({
              type: "success",
              message: that.language_type
                ? "图片上传成功"
                : "Image uploaded successfully",
            });
            if (fileList.length > 1) {
              fileList.shift();
            }
            that.fileList2 = fileList;
            that.testImg = f.target.result;
            that.sureMultiple(f.target.result, "test");
          }
        };
      };
      reader.readAsDataURL(file.raw);
    },
    uploadhandleChange(file, fileList) {
      var that = this;
      let reader = new FileReader();
      reader.onloadend = (f) => {
        var objSrc = f.target.result;
        var imageObj = new Image();
        imageObj.src = objSrc;
        imageObj.onload = () => {
          if (imageObj.width > 4000 || imageObj.height > 4000) {
            that.$message({
              type: "warning",
              message: that.language_type
                ? "图片尺寸过大，请重新上传"
                : "The picture size is too large, please upload it again",
            });
            if (fileList.length > 1) {
              fileList.pop();
            }
            that.uploadfileList = fileList;
          } else if (imageObj.width < 400 || imageObj.height < 400) {
            that.$message({
              type: "warning",
              message: that.language_type
                ? "图像分辨率太小，仅限分辨率大于400*400的图像"
                : "Image resolution is too small, only images with a resolution greater than 400*400",
            });
            if (fileList.length > 1) {
              fileList.pop();
            }
            that.uploadfileList = fileList;
          } else {
            that.$message({
              type: "success",
              message: that.language_type
                ? "图片上传成功"
                : "Image uploaded successfully",
            });
            if (fileList.length > 1) {
              fileList.shift();
            }
            that.uploadfileList = fileList;
            that.uploadtoopImg = f.target.result;
          }
        };
      };
      reader.readAsDataURL(file.raw);
    },
    uploadhandleChange2(file, fileList) {
      var that = this;
      let reader = new FileReader();
      reader.onloadend = (f) => {
        var objSrc = f.target.result;
        var imageObj = new Image();
        imageObj.src = objSrc;
        imageObj.onload = () => {
          if (imageObj.width > 4000 || imageObj.height > 4000) {
            that.$message({
              type: "warning",
              message: that.language_type
                ? "图片尺寸过大，请重新上传"
                : "The picture size is too large, please upload it again",
            });
            if (fileList.length > 1) {
              fileList.pop();
            }
            that.uploadfileList2 = fileList;
          } else if (imageObj.width < 400 || imageObj.height < 400) {
            that.$message({
              type: "warning",
              message: that.language_type
                ? "图像分辨率太小，仅限分辨率大于400*400的图像"
                : "Image resolution is too small, only images with a resolution greater than 400*400",
            });
            if (fileList.length > 1) {
              fileList.pop();
            }
            that.uploadfileList2 = fileList;
          } else {
            that.$message({
              type: "success",
              message: that.language_type
                ? "图片上传成功"
                : "Image uploaded successfully",
            });
            if (fileList.length > 1) {
              fileList.shift();
            }
            that.uploadfileList2 = fileList;
            that.uploadtestImg = f.target.result;
          }
        };
      };
      reader.readAsDataURL(file.raw);
    },
    drawerSure() {
      if (this.radio1 == "first") {
        if (this.drawerImgIndex == 99) {
          this.redToopis = "请选择一个样例";
          return;
        }
        this.toopImg = this.drawerImgList[this.drawerImgIndex].imgl;
        this.testImg = this.drawerImgList[this.drawerImgIndex].imgr;
      } else {
        if (this.uploadtoopImg == "" || this.uploadtestImg == "") {
          this.redToopis = "请上传图像";
          return;
        }
        this.toopImg = this.uploadtoopImg;
        this.testImg = this.uploadtestImg;
        this.drawerImgIndex = 99;
      }
      this.innerDrawer = false;
      this.sureMultiple(this.toopImg, "toop");
      this.sureMultiple(this.testImg, "test");
    },
    // 根据图片确定比例
    sureMultiple(img, type) {
      var imageObj = new Image();
      imageObj.src = img;
      imageObj.onload = () => {
        // 调整比例
        let imgMultiple = imageObj.width / imageObj.height;
        let boxMultiple = 450 / 450;
        if (type == "toop") {
          this.toopMultiple = {
            pointMultiple: 0,
            Imgx: 0,
            Imgy: 0,
          };
          if (imgMultiple > boxMultiple) {
            this.toopMultiple.pointMultiple = 450 / imageObj.width;
            this.toopMultiple.Imgy =
              Math.abs(450 - (imageObj.height * 450) / imageObj.width) / 2;
          } else {
            this.toopMultiple.pointMultiple = 450 / imageObj.height;
            this.toopMultiple.Imgx =
              Math.abs(450 - (imageObj.width * 450) / imageObj.height) / 2;
          }
          this.markList = [];
          this.initCanvas();
        } else {
          this.testMultiple = {
            pointMultiple: 0,
            Imgx: 0,
            Imgy: 0,
          };
          if (imgMultiple > boxMultiple) {
            this.testMultiple.pointMultiple = 450 / imageObj.width;
            this.testMultiple.Imgy =
              Math.abs(450 - (imageObj.height * 450) / imageObj.width) / 2;
          } else {
            this.testMultiple.pointMultiple = 450 / imageObj.height;
            this.testMultiple.Imgx =
              Math.abs(450 - (imageObj.width * 450) / imageObj.height) / 2;
          }
        }
      };
    },
    /* 画布初始化 */
    initCanvas() {
      // 初始化canvas宽高
      let cav = document.getElementsByClassName("markCanvas")[0];
      console.log(cav);
      cav.width = 450 - this.toopMultiple.Imgx * 2;
      cav.height = 450 - this.toopMultiple.Imgy * 2;
      cav.style.top = this.toopMultiple.Imgy / 100 + "rem";
      cav.style.left = this.toopMultiple.Imgx / 100 + "rem";
      let ctx = cav.getContext("2d");
      ctx.strokeStyle = "blue";
      cav.style.cursor = "crosshair";

      // 计算使用变量
      let list = this.markList; // 画框数据集合, 用于服务端返回的数据显示和绘制的矩形保存
      // 若服务端保存的为百分比则此处需计算实际座标, 直接使用实际座标可省略
      list.forEach(function (value, index, array) {
        let newValue = {
          x: value.x * cav.width,
          y: value.y * cav.height,
          w: value.w * cav.width,
          h: value.h * cav.height,
        };
        list.splice(index, 1, newValue);
        console.log(array);
      });

      // 若list长度不为0, 则显示已标记框
      if (list.length !== 0) {
        list.forEach(function (value, index, array) {
          console.log(index, array);
          // 遍历绘制所有标记框
          ctx.rect(value.x, value.y, value.w, value.h);
          ctx.stroke();
        });
      }

      // 调用封装的绘制方法
      draw(cav, list);
    },
    tsubmit() {
      console.log(this.markList);
    },
    refresh() {
      this.uploadtoopImg = "";
      this.uploadtestImg = "";
      this.redToopis = "";
      this.innerDrawer = true;
    },
    drawPoint(list) {
      let divs = document.getElementsByClassName("canvasDiv");
      let spans = document.getElementsByClassName("canvasText");
      let img = new Image();
      img.src = this.imgUrl;
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        // 调整比例
        let imgMultiple = img.width / img.height;
        let boxMultiple = 740 / 415;
        this.multiple = {
          pointMultiple: 0,
          Imgx: 0,
          Imgy: 0,
        };
        if (imgMultiple > boxMultiple) {
          this.multiple.pointMultiple = 740 / img.width;
          this.multiple.Imgy =
            Math.abs(415 - (img.height * 740) / img.width) / 2;
        } else {
          this.multiple.pointMultiple = 415 / img.height;
          this.multiple.Imgx =
            Math.abs(740 - (img.width * 415) / img.height) / 2;
        }
        let num = 0;
        let lineWidth = 1;
        let size = 12;
        for (let i = 0; i < list.length; i++) {
          let x1 =
            list[i].bbox[0] * this.multiple.pointMultiple + this.multiple.Imgx;
          let y1 =
            list[i].bbox[1] * this.multiple.pointMultiple + this.multiple.Imgy;
          let x2 =
            list[i].bbox[2] * this.multiple.pointMultiple + this.multiple.Imgx;
          let y2 =
            list[i].bbox[3] * this.multiple.pointMultiple + this.multiple.Imgy;
          let wid = x2 - x1;
          let hei = y2 - y1;
          // 设置颜色
          if (num >= this.colorTotal.length) {
            num -= this.colorTotal.length;
          }
          divs[i].style.width = wid / 100 + "rem";
          divs[i].style.height = hei / 100 + "rem";
          divs[i].style.left = x1 / 100 + "rem";
          divs[i].style.top = y1 / 100 + "rem";
          divs[i].style.border =
            lineWidth / 100 + "rem solid " + this.colorTotal[num];
          spans[i].style.background = this.colorTotal[num];
          spans[i].style.fontSize = size / 100 + "rem";
          num++;
        }
      };
      this.downloadDrawPoint(list);
    },
    // 下载时绘制
    downloadDrawPoint(list) {
      let img = new Image();
      img.src = this.imgUrl;
      img.setAttribute("crossOrigin", "anonymous");
      let cvs = document.createElement("canvas");
      let ctx = cvs.getContext("2d");
      img.onload = () => {
        // 调整比例
        cvs.width = img.width;
        cvs.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        let colorArr = [];
        let num = 0;
        let hovernum = 0;
        let color = "";
        if (img.width < 740) {
          ctx.lineWidth = 1;
        } else if (img.width < 1480) {
          ctx.lineWidth = 2;
        } else if (img.width < 2220) {
          ctx.lineWidth = 3;
        } else if (img.width < 2960) {
          ctx.lineWidth = 4;
        } else {
          ctx.lineWidth = 5;
        }
        for (let i = 0; i < list.length; i++) {
          let x1 = list[i].bbox[0];
          let y1 = list[i].bbox[1];
          let x2 = list[i].bbox[2];
          let y2 = list[i].bbox[3];
          let wid = x2 - x1;
          let hei = y2 - y1;
          // 设置颜色
          if (num >= this.colorTotal.length) {
            num -= this.colorTotal.length;
          }
          ctx.strokeStyle = this.colorTotal[num];
          colorArr.push(this.colorTotal[num]);
          if (this.indexArr.length > 0 && i == this.indexArr[0]) {
            hovernum = num;
            color = this.colorTotal[num];
          } else {
            ctx.strokeRect(x1, y1, wid, hei);
          }
          num++;
        }
        if (this.indexArr.length > 0) {
          let x1 = list[hovernum].bbox[0];
          let y1 = list[hovernum].bbox[1];
          let x2 = list[hovernum].bbox[2];
          let y2 = list[hovernum].bbox[3];
          let wid = x2 - x1;
          let hei = y2 - y1;
          ctx.strokeStyle = color;
          ctx.fillRect(x1, y1, wid, hei);
        }
        for (let i = 0; i < list.length; i++) {
          let x1 = list[i].bbox[0];
          let y1 = list[i].bbox[1];
          let text = list[i].label;
          // 设置颜色
          ctx.fillStyle = colorArr[i];
          if (img.width < 740) {
            ctx.fillRect(x1, y1, 20 + 6 * text.length, 30);
            ctx.fillStyle = "#fff";
            ctx.font = "12px Arial";
            ctx.fillText(text, x1 + 10, y1 + 20);
          } else if (img.width < 1480) {
            ctx.fillRect(x1, y1, 20 + 7 * text.length, 30);
            ctx.fillStyle = "#fff";
            ctx.font = "14px Arial";
            ctx.fillText(text, x1 + 10, y1 + 20);
          } else if (img.width < 2220) {
            ctx.fillRect(x1, y1, 24 + 7 * text.length, 30);
            ctx.fillStyle = "#fff";
            ctx.font = "16px Arial";
            ctx.fillText(text, x1 + 10, y1 + 20);
          } else if (img.width < 2960) {
            ctx.fillRect(x1, y1, 24 + 8 * text.length, 40);
            ctx.fillStyle = "#fff";
            ctx.font = "18px Arial";
            ctx.fillText(text, x1 + 10, y1 + 24);
          } else {
            ctx.fillRect(x1, y1, 24 + 9 * text.length, 40);
            ctx.fillStyle = "#fff";
            ctx.font = "20px Arial";
            ctx.fillText(text, x1 + 10, y1 + 24);
          }
        }
        this.NewimgUrl = cvs.toDataURL("image/png");
        this.base64(this.NewimgUrl).then((base_res) => {
          this.downImg = base_res;
        });
      };
    },
    uploadImage(e) {
      var that = this;
      that.uploadtake = true;
      this.file = e;
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onloadend = (f) => {
        that.loading = true;
        var objSrc = f.target.result;
        var imageObj = new Image();
        imageObj.src = objSrc;
        imageObj.onload = () => {
          if (imageObj.width > 4000 || imageObj.height > 4000) {
            that.$message({
              type: "warning",
              message: that.language_type
                ? "图片尺寸过大，请重新上传"
                : "The picture size is too large, please upload it again",
            });
            that.loading = false;
          } else if (imageObj.width < 400 || imageObj.height < 400) {
            that.$message({
              type: "warning",
              message: that.language_type
                ? "图像分辨率太小，仅限分辨率大于400*400的图像"
                : "Image resolution is too small, only images with a resolution greater than 400*400",
            });
            that.loading = false;
          } else {
            that.ImgName = file.name; //获取文件名称
            that.suffix = that.ImgName.substring(
              that.ImgName.lastIndexOf(".") + 1
            ); //获取拓展名
            that.strImgName = that.ImgName.split(".")[0];
            that.file = file;
            that.list = [];
            that.eyeBtn = false;
            that.imgUrl = f.target.result;
            that.NewimgUrl = f.target.result;
            that.tagList = ["MS-COCO"];
            var downloadType = f.target.result.slice(
              0,
              f.target.result.indexOf(",")
            );
            that.loading = false;
            that.downImgType = downloadType;
            that.$message({
              type: "success",
              message: that.language_type
                ? "图片上传成功"
                : "Image uploaded successfully",
            });
          }
        };
      };
      reader.readAsDataURL(file);
    },
    submit() {
      var that = this;
      if (that.file == "") {
        this.$message({
          type: "warning",
          message: this.language_type
            ? "请先上传图片"
            : "Please upload pictures first",
        });
      } else if (that.tagList.length == 0) {
        this.$message({
          type: "warning",
          message: this.language_type
            ? "请设置识别标签"
            : "Please set identification tags",
        });
      } else if (that.file) {
        let imgT =
          "https://50058.gzc.svp.tencent-cloud.com/0b53oibwiaadxaaozsg3xjrys4wdmrzagzaa.f0.png?dis_k=867894a49d34b36a9749f3589af8fcf1&dis_t=1675135178";
        let imageT = new Image();
        imageT.src = imgT;
        imageT.setAttribute("crossOrigin", "Anonymous");
        imageT.onload = function () {
          let base64 = that.getBase64Image(imageT);
          let file2 = that.btof(base64, "test");
          let arr = that.tagList.slice();
          that.loading = true;
          for (let index = 0; index < that.tagList.length; index++) {
            if (that.tagList[index] == "MS-COCO") {
              arr.splice(index, 1);
              arr = arr.concat(that.tagListdig);
              break;
            }
          }
          var forData = new FormData();
          forData.append("file", that.file);
          forData.append("file2", file2);
          forData.append("text_content", arr.join(","));
          forData.append("max_num_boxes", that.dialogData.boxNum);
          forData.append("nms_thr", that.dialogData.nmsNum);
          forData.append("score_thr", that.dialogData.scoreNum);
          forData.append("image_name", that.ImgName);
          let potss = forData;
          let urls = "https://arc.tencent.com/yolo_world/";
          that.axios
            .post(urls, potss)
            .then((res) => {
              if (res.data.code == "200") {
                let img = new Image();
                img.src = window.URL.createObjectURL(that.file);
                img.onload = function () {
                  that.canvasWidth = img.width;
                  that.canvasHeight = img.height;
                };
                let list = eval("(" + res.data.data + ")").predictions;
                for (let i = 0; i < list.length; i++) {
                  let text = "";
                  let tArr = list[i].label.split(" ");
                  for (let j = 0; j < tArr.length - 1; j++) {
                    text += tArr[j] + " ";
                  }
                  list[i].label =
                    text +
                    (Number(tArr[tArr.length - 1].split("%")[0]) / 100).toFixed(
                      2
                    );
                }
                that.list = list;
                that.eyeOpen = true;
                that.eyeBtn = true;
                that.nowIndex = 9999;
                that.drawPoint(list);
                that.loading = false;
                that.$message({
                  type: "success",
                  message: that.language_type ? "加载成功" : "Success",
                });
              } else {
                that.loading = false;
                that.$message({
                  type: "error",
                  message: that.language_type
                    ? "返回结果失败，请稍后再试"
                    : "Failed to return results. Please try again later",
                });
              }
            })
            .catch(() => {
              that.loading = false;
              that.nowIndex = 9999;
              that.eyeOpen = true;
              that.eyeBtn = true;
              that.$message({
                type: "error",
                message: that.language_type
                  ? "当前调用的人太多啦，请稍后再试"
                  : "Too many people are calling now. Please try again later",
              });
            });
        };
      }
    },
    base64(url) {
      return new Promise((resolve) => {
        const image = new Image();
        // 先设置图片跨域属性
        image.crossOrigin = "Anonymous";
        // 再给image赋值src属性，先后顺序不能颠倒
        image.src = url;
        image.onload = function () {
          const canvas = document.createElement("CANVAS");
          // 设置canvas宽高等于图片实际宽高
          canvas.width = image.width;
          canvas.height = image.height;
          canvas.getContext("2d").drawImage(image, 0, 0);
          // toDataUrl可以接收2个参数，参数一：图片类型，参数二： 图片质量0-1（不传默认为0.92）
          const dataURL = canvas.toDataURL("image/jpeg");
          resolve(dataURL);
        };
        image.onerror = () => {
          resolve({ message: "图片处理失败" });
        };
      });
    },
    downloadIamge() {
      if (this.downImg.length == "0") {
        this.$message({
          type: "warning",
          message: this.language_type
            ? "请先上传图片"
            : "Please upload pictures first",
        });
      } else {
        this.dowmtake = true;
        var Namesuffix = this.language_type ? "_识别后" : "_after";
        // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
        if (window.navigator.msSaveOrOpenBlob) {
          let bstr = atob(this.downImg.split(",")[1]);
          let n = bstr.length;
          let u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          let blob = new Blob([u8arr]);
          window.navigator.msSaveOrOpenBlob(
            blob,
            this.strImgName + Namesuffix + "." + this.suffix
          );
        } else {
          // 这里就按照chrome等新版浏览器来处理
          let a = document.createElement("a");
          a.href = this.downImg;
          a.setAttribute(
            "download",
            this.strImgName + Namesuffix + "." + this.suffix
          );
          a.click();
        }
      }
    },
    slideImg() {
      this.$refs.widthStyle.style.width = this.value + "%";
      this.$refs.leftStyle.style.left = this.value + "%";
      if (this.first == 1) {
        this.first++;
      } else {
        // 鼠标滑过效果互斥
        this.mouse_disable = true;
      }
    },
    mouseMove() {
      this.mouse_disable = false;
    },
    // 创建微信二维码
    creatQrCode() {
      var wx_qr = document.getElementsByClassName("code");
      var zh_path, en_path;
      if (this.$route.path.slice(1, 3) == "zh") {
        zh_path = this.$route.path;
        en_path = this.$route.path.replace("/zh/", "/en/");
      } else {
        zh_path = this.$route.path.replace("/en/", "/zh/");
        en_path = this.$route.path;
      }
      var path = "https://arc.tencent.com" + zh_path;
      var path2 = "https://arc.tencent.com" + en_path;
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: path, // 需要转换为二维码的内容
        width: wx_qr[0].offsetWidth,
        height: wx_qr[0].offsetHeight,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      var qrcode2 = new QRCode(this.$refs.qrCodeUrl2, {
        text: path2, // 需要转换为二维码的内容
        width: wx_qr[0].offsetWidth,
        height: wx_qr[0].offsetHeight,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      console.log(qrcode, qrcode2);
    },
    wxShareAppMessage() {
      wx.config({
        debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: "wx80d0a7314195c39e", // 必填，公众号的唯一标识
        timestamp: "1414587457", // 必填，生成签名的时间戳
        nonceStr: "Wm3WZYTPz0wzccnW", // 必填，生成签名的随机串
        signature: "0f9de62fce790f9a083d5c99e95740ceb90c27ed", // 必填，签名
        jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
      });
      wx.ready(function () {
        //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
          title: "动漫增强", // 分享标题
          desc: "增强动漫和插画图像", // 分享描述
          link: "https://arc.tencent.com/ai-demos/imgRestore", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl:
            "https://50058.gzc.svp.tencent-cloud.com/0b53lqadaaaat4ajeuavpzriuxgdgboaamaa.f0.jpg?dis_k=d924b0483964c3a9c031c8889a0c4c47&dis_t=1645585091", // 分享图标
          success: function () {
            alert("分享成功");
          },
          cancel: function () {
            alert("分享失败");
          },
        });
        wx.updateTimelineShareData({
          title: "动漫增强-增强动漫和插画图像", // 分享标题
          link: "https://arc.tencent.com/ai-demos/imgRestore", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl:
            "https://50058.gzc.svp.tencent-cloud.com/0b53lqadaaaat4ajeuavpzriuxgdgboaamaa.f0.jpg?dis_k=d924b0483964c3a9c031c8889a0c4c47&dis_t=1645585091", // 分享图标
          success: function () {
            // 设置成功
          },
        });
      });
      wx.error(function () {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        alert("验证失败");
      });
    },
    // 分享到新浪微博
    share_weibo(event) {
      event.preventDefault();
      // shareUrl是微博的分享地址，（有资料说需要真实的appkey，必选参数，这里我没用appkey也可以正常分享）
      var _shareUrl = "http://v.t.sina.com.cn/share/share.php?";
      _shareUrl +=
        "url=" +
        encodeURIComponent("https://arc.tencent.com/ai-demos/imgRestore"); //参数url设置分享的内容链接
      _shareUrl +=
        "&title=" + encodeURIComponent("动漫增强-增强动漫和插画图像"); //参数title设置分享的标题
      _shareUrl +=
        "&pic=" + encodeURIComponent(this.NewimgUrl) + "&searchPic=true";
      // 保留当前页面,打开一个非tab页面（按需求来，可以新开标签页，也可以在当前页新开页面）
      window.open(
        _shareUrl,
        "_blank",
        "height=400, width=400",
        "scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes"
      );
    },
    shareToQQ() {
      //此处分享链接内无法携带图片
      const share = {
        title: "动漫增强",
        desc: "增强动漫和插画图像",
        share_url: "https://arc.tencent.com/ai-demos/imgRestore",
      };
      var _shareUrl =
        "https://connect.qq.com/widget/shareqq/index.html?url=" +
        encodeURIComponent(share.share_url) +
        "&title=" +
        share.title +
        "&desc=" +
        share.desc;
      window.open(
        _shareUrl,
        "打开qq分享",
        "height=700, width=800,left=500,top=200",
        "scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes"
      );
    },
    //分享到贴吧
    shareToTieba(event) {
      event.preventDefault();
      var _shareUrl = "http://tieba.baidu.com/f/commit/share/openShareApi?";
      const share = {
        title: "动漫增强-增强动漫和插画图像",
        share_url: "https://arc.tencent.com/ai-demos/imgRestore",
        pic: this.NewimgUrl,
        _width: 670,
        _height: 550,
        _top: (screen.height - 550) / 2,
        _left: (screen.width - 670) / 2,
      };
      _shareUrl += "title=" + encodeURIComponent(share.title || document.title); //分享的标题
      _shareUrl +=
        "&url=" + encodeURIComponent(share.share_url || document.location); //分享的链接
      _shareUrl += "&pic=" + encodeURIComponent(share.pic || ""); //分享的图片
      window.open(
        _shareUrl,
        "_blank",
        "width=" +
          share._width +
          ",height=" +
          share._height +
          ",left=" +
          share._left +
          ",top=" +
          share._top +
          ",toolbar=no,menubar=no,scrollbars=no,resizable=1,location=no,status=0"
      );
    },
    //分享到Facebook
    shareToFacebook(event) {
      event.preventDefault();
      var _shareUrl = "http://www.facebook.com/sharer/sharer.php?";
      const share = {
        title: "动漫增强-增强动漫和插画图像",
        share_url: "https://arc.tencent.com/ai-demos/imgRestore",
        _width: 670,
        _height: 550,
        _top: (screen.height - 550) / 2,
        _left: (screen.width - 670) / 2,
      };
      _shareUrl += "u=" + encodeURIComponent(share.share_url || location.href); //分享的链接
      _shareUrl += "&t=" + encodeURIComponent(share.title || document.title); //分享的标题
      window.open(
        _shareUrl,
        "_blank",
        "width=" +
          share._width +
          ",height=" +
          share._height +
          ",left=" +
          share._left +
          ",top=" +
          share._top +
          ",toolbar=no,menubar=no,scrollbars=no,resizable=1,location=no,status=0"
      );
    },
    //分享到豆瓣
    shareToDouban(event) {
      event.preventDefault();
      const share = {
        title: "动漫增强-增强动漫和插画图像",
        share_url: "https://arc.tencent.com/ai-demos/imgRestore",
        pic: this.NewimgUrl,
      };
      var _shareUrl = "http://shuo.douban.com/!service/share?";
      _shareUrl +=
        "href=" + encodeURIComponent(share.share_url || location.href); //分享的链接
      _shareUrl += "&name=" + encodeURIComponent(share.title || document.title); //分享的标题
      _shareUrl += "&image=" + encodeURIComponent(share.pic || ""); //分享的图片
      window.open(_shareUrl, "_blank");
    },
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
      var dataURL = canvas.toDataURL("image/" + ext);
      return dataURL;
    },
    btof(data, fileName) {
      const dataArr = data.split(",");
      const byteString = atob(dataArr[1]);
      const options = {
        type: "image/jpeg",
        endings: "native",
      };
      const u8Arr = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        u8Arr[i] = byteString.charCodeAt(i);
      }
      return new File([u8Arr], fileName + ".jpg", options);
    },
  },
};
</script>
  <style scoped lang='less'>
/deep/.el-loading-spinner .path {
  stroke: #babdc5;
}

/deep/.el-loading-mask {
  z-index: 25;
}

/deep/ .el-image__inner {
  object-fit: contain;
}

.imgRestore {
  max-width: 9.96rem;
  margin: 0 auto;

  .modelBox {
    display: flex;
    height: 0.46rem;
    align-items: center;
    margin-top: 0.3975rem;
    color: #222222;
    font-weight: 400;
    font-size: 0.14rem;

    .modelTitle {
      margin-right: 0.08rem;
    }

    .modelSelect,
    .PitchOn_first,
    .PitchOn_last {
      padding: 0.12rem 0.16rem;
      background: #f3f5f8;
      border-radius: 0.02rem;
      margin-left: 0.16rem;
      cursor: pointer;
    }

    .modelSelect:hover {
      color: #2e54eb;
    }

    .modelSelect:active {
      color: #2e54eb;
    }

    .PitchOn_first,
    .PitchOn_last {
      border: 0.01rem solid #2e54eb;
      color: #2e54eb;
      background-color: #ecf3ff;
      font-weight: 600;
    }
  }
  .functionBox {
    background-color: #f2f6fa;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 2.93rem;
    margin-top: 0.12rem;
    .functionLeft {
      position: relative;
      width: 7.4rem;
      height: 4.15rem;
      border-radius: 0.02rem;
      overflow: hidden;
      .oldimgUrl {
        overflow: hidden;
        position: absolute;
        height: 4.15rem;
        z-index: 11;

        .beforelabel {
          position: absolute;
          width: 0.66rem;
          height: 0.38rem;
          left: 0;
          top: 0;
          background: #00031e;
          opacity: 0.5;
          border-radius: 0.02rem;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 0.14rem;
          line-height: 0.38rem;
          color: #fff;
          text-align: center;
          z-index: 1;
        }

        .imgUrl {
          width: 7.4rem;
          height: 4.15rem;
          object-fit: contain;
        }
      }

      .newimgUrl {
        position: absolute;
        width: 7.4rem;
        height: 4.15rem;
        background-color: #e2e8ef;
        #myCanvas {
          width: 7.4rem;
          height: 4.15rem;
          position: absolute;
          top: 0;
          left: 0;
        }
        .imgUrl {
          width: 7.4rem;
          height: 4.15rem;
          object-fit: contain;
        }
        .canvasDiv {
          position: absolute;
          background: transparent;
          box-sizing: border-box;
          .canvasText {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0.02rem 0.05rem;
            color: #fff;
            font-family: Arial;
            white-space: nowrap;
          }
        }
        .activeCanvas {
          z-index: 98;
        }
        .move {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 99;
          background: transparent;
        }

        .arcLogo {
          position: absolute;
          width: 0.88rem;
          height: 0.22rem;
          right: 0.1rem;
          bottom: 0.1rem;
        }
      }

      .slideRectangle {
        position: absolute;
        margin-left: -0.38rem;
        z-index: 22;

        .Slice {
          position: absolute;
          bottom: -0.08rem;
          width: 0.76rem;
          height: 0.53rem;

          img {
            width: 0.76rem;
            height: 0.53rem;
          }
        }

        .Rectangle {
          position: absolute;
          left: 0.38rem;
          bottom: -0.2rem;
          width: 1px;
          height: 4.15rem;

          img {
            width: 1px;
            height: 4.15rem;
          }
        }

        .afterlabel {
          width: 0.66rem;
          height: 0.38rem;
          position: absolute;
          top: -3.95rem;
          left: 0.39rem;
          background: #00031e;
          opacity: 0.5;
          border-radius: 0.02rem;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 0.14rem;
          line-height: 0.38rem;
          color: #fff;
          text-align: center;
          z-index: 1;
        }
      }

      /deep/.el-slider {
        margin-top: 3.57rem;

        .el-slider__runway {
          background-color: rgba(255, 255, 255, 0);
        }

        .el-slider__bar {
          background-color: rgba(255, 255, 255, 0);
        }

        .el-slider__button-wrapper {
          width: 0.76rem;

          .el-slider__button {
            z-index: 999;
            width: 0.5rem;
            height: 0.3rem;
            border: 0;
            background-color: rgba(255, 255, 255, 0);
          }
        }
      }
    }

    .functionRight {
      flex: 1;
      padding-left: 0.26rem;
      padding-right: 0.24rem;
      padding-top: 0.23rem;

      .imgList {
        display: block;
        width: 2.06rem;
        height: 1.16rem;
        object-fit: cover;
        box-sizing: border-box;
        border: solid 0.02rem transparent;
      }

      img:nth-child(1) {
        margin-bottom: 0.23rem;
      }

      img:nth-child(2) {
        margin-bottom: 0.23rem;
      }

      .active {
        border: solid 0.02rem #2e54eb;
      }
    }
    .t-contentBox {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      position: relative;
      .title_box {
        height: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.1rem;
      }
      .title {
        font-size: 0.16rem;
        line-height: 0.4rem;
      }
      .upload {
        padding: 10px;
      }
      .t-Box {
        width: 4.5rem;
        height: 100%;
        position: relative;
        .img_box {
          background: #e2e8ef;
          position: relative;
        }
        .toop_box {
          cursor: not-allowed;
        }
        img {
          display: block;
          width: 100%;
          height: 4.5rem;
          object-fit: contain;
        }
        .markCanvas {
          position: absolute;
        }
      }
      .t-center {
        width: 0.98rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .btn {
          width: 0.8rem;
          background-color: #2e54eb;
          height: 0.54rem;
          line-height: 0.2rem;
          border-radius: 0.1rem;
          color: #fff;
          font-size: 0.14rem;
          text-align: center;
          position: relative;
          cursor: pointer;
          padding-top: 0.05rem;
          box-shadow: 0 0.02rem 0 rgba(5, 105, 255, 0.08);
          i {
            font-size: 0.2rem;
          }
        }
        .open {
          color: #1d2129;
          background-color: #fff;
          border: 1px solid #e5e6eb;
          margin-top: 0.2rem;
        }
        .btn:hover {
          background-color: #486dff;
          transform: scale(1.1);
          color: #fff;
        }
      }
    }
    .drawerContentBox {
      height: 92%;
      padding: 0.4rem 0.3rem 0 0.3rem;
      p {
        font-size: 0.14rem;
        margin-bottom: 0.08rem;
        font-weight: 800;
      }
      .el-radio-group {
        width: 100%;
        .el-radio-button {
          width: 50%;
        }
      }
      /deep/.el-radio-button__inner {
        width: 100%;
      }
      .drawerImgBox {
        width: 100%;
        margin-top: 0.1rem;
        height: 90%;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          // 隐藏滚动条将宽度设置为0
          background-color: #e8eaec;
          width: 0 !important;
        }
        li {
          height: 1.44rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 0.06rem;
          overflow: hidden;
          margin-bottom: 0.12rem;
          border: 0.02rem solid transparent;
          position: relative;
          img {
            width: 49%;
            height: 100%;
            object-fit: cover;
          }
        }
        .active {
          border: 0.02rem solid #1e53f5;
          img {
            opacity: 0.6;
          }
          .el-icon-success {
            color: #1e53f5;
            font-size: 0.14rem;
            font-weight: 600;
            position: absolute;
            top: 0.1rem;
            right: 0.05rem;
          }
        }
        li:hover {
          border: 0.02rem solid #1e53f5;
        }
      }
      .drawerUploadBox {
        .upload-demo {
          position: relative;
          .upload_img {
            position: absolute;
            top: 5%;
            left: 10%;
            width: 80%;
            height: 90%;
            object-fit: contain;
          }
        }
        p {
          color: #86909c;
          margin-top: 0.08rem;
          font-weight: 400;
        }
      }
    }
    .drawerBtnBox {
      height: 8%;
      box-sizing: border-box;
      border-top: 1px solid #e2e2f6;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .redToopis {
        font-size: 0.14rem;
        position: absolute;
        top: -0.3rem;
        left: 0.3rem;
        color: #ff4d4f;
      }
      .el-button {
        width: 80%;
      }
    }
  }
  .el-dialog__body {
    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0.2rem;
      .label {
        width: 2rem;
        .num {
          color: #2d8cf0;
        }
      }
      .label_en {
        width: 3.3rem;
        .num {
          color: #2d8cf0;
        }
      }
      .el-slider {
        width: 100%;
      }
    }
  }
  .tagdia {
    .el-tag {
      margin: 0.04rem;
    }
  }
  .btnBox {
    width: 100%;
    padding-top: 0.2rem;
    padding-bottom: 0.3rem;
    box-sizing: content-box;
    position: relative;
    .tagBox {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0.2rem 0 0.2rem 0.1rem;
      .label {
        min-width: 20%;
        color: #222222;
        font-weight: 400;
        font-size: 0.14rem;
      }
      .label_en {
        min-width: 15%;
        color: #222222;
        font-weight: 400;
        font-size: 0.14rem;
      }
      .tag_con {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .el-tag {
          margin: 0.04rem;
        }
        .input-new-tag {
          width: 1.2rem;
          margin: 0.04rem;
        }
        .button-new-tag {
          margin: 0.04rem;
        }
        .icon-yanjingkaiyan {
          color: #409eff;
          cursor: pointer;
          font-size: 0.2rem;
          margin-left: 0.1rem;
        }
        .icon-yanjingguan1 {
          font-size: 0.22rem;
          cursor: pointer;
          margin-left: 0.1rem;
        }
      }
    }
    .prompt {
      position: absolute;
      top: 0.05rem;
      left: 0.05rem;
      font-size: 0.12rem;
    }
    .cooperation {
      position: absolute;
      bottom: -0.25rem;
      left: 0.05rem;
      font-size: 0.14rem;
    }
    .options {
      position: absolute;
      top: 0.03rem;
      right: 2.6rem;
      color: #2d8cf0;
      cursor: pointer;
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      .iconfont {
        font-size: 0.2rem;
      }
    }

    .btnLeft {
      float: left;
      display: flex;
      margin-left: 0.3rem;
      /deep/.uploadBtn {
        background-color: #2e54eb;
        width: 1.6rem;
        height: 0.46rem;
        line-height: 0.46rem;
        border-radius: 0.02rem;
        color: #fff;
        font-size: 0.16rem;
        text-align: center;
        margin-right: 0.2rem;
        position: relative;
        cursor: pointer;
      }
      /deep/.uploadInput {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        overflow: hidden;
        cursor: pointer;
        padding: 0.25rem;
      }

      /deep/.uploadBtn:hover {
        background-color: #486dff;
      }

      /deep/.uploadBtn:active {
        background-color: #002acf;
      }

      /deep/.clickupload {
        background-color: #002acf;
      }

      /deep/.dowmBtn,
      /deep/.mouse_disable {
        width: 1.6rem;
        height: 0.46rem;
        line-height: 0.46rem;
        border-radius: 0.02rem;
        font-size: 0.16rem;
        text-align: center;
        border: 1px solid #2e54eb;
        color: #2e54eb;
        cursor: pointer;
      }

      /deep/.dowmBtn:hover {
        color: #fff;
        background-color: #486dff;
        border: 1px solid transparent;
      }

      /deep/.dowmBtn:active {
        color: #fff;
        background-color: #002acf;
        border: 1px solid transparent;
      }
    }

    .btnRight {
      height: 0.46rem;
      flex: 1;
      margin-right: 0.08rem;
      display: flex;
      align-items: center;
      float: right;

      .shareTo {
        font-weight: 600;
        font-family: PingFang SC;
        font-size: 0.14rem;
        line-height: 0.2rem;
        margin-right: 0.22rem;
        color: #222;
      }

      .shareIcon {
        position: relative;
        margin-right: 0.16rem;
        width: 0.28rem;
        height: 0.28rem;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }

        .normal_img {
          display: inline-block;
        }

        .hover_img {
          display: none;
        }

        .wx_code {
          width: 1.52rem;
          height: 1.8rem;
          background: #ffffff;
          border: 1px solid #d9e0e8;
          box-sizing: border-box;
          box-shadow: 0rem 0.04rem 0.15rem rgba(108, 121, 154, 0.201759);
          border-radius: 0.02rem;
          position: absolute;
          top: 0.28rem;
          left: -0.62rem;
          visibility: hidden;

          .code {
            width: 1.2rem;
            height: 1.2rem;
            margin: 0.16rem 0.16rem 0.08rem;
          }

          p {
            height: 0.2rem;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 600;
            font-size: 0.14rem;
            line-height: 0.2rem;
            color: #434343;
            text-align: center;
          }
        }
      }

      .shareIcon:hover {
        .normal_img {
          display: none;
        }

        .hover_img {
          display: inline-block;
        }

        .wx_code {
          visibility: inherit;
        }
      }

      .hover_img:active {
        opacity: 0.7;
      }
    }
  }

  .functionBox_mini {
    margin-top: 0.8rem;

    .message {
      width: 80%;
      margin: 0 auto;
      font-family: PingFangSC-Regular, sans-serif;
      font-size: 0.3rem;
      color: #666666;
      line-height: 0.52rem;
      text-align: center;
    }

    .wxapp_box {
      width: 3.4rem;
      height: 3.4rem;
      margin: 0.4rem auto 0;
      background: #ffffff;
      border: 1px solid #d9e0e8;
      box-sizing: border-box;
      box-shadow: 0px 0.04rem 0.15rem rgb(108 121 154 / 20%);
      border-radius: 0.02rem;
      text-align: center;
      padding-top: 0.2rem;

      img {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}
</style>